import { useRouter } from 'next/router'
import React, { useContext } from 'react'

import SignoutTrigger from '@/components/shared/components/module/auth/SignoutTrigger'
import Avatar from '@/components/shared/ui/Avatar'
import Button from '@/components/shared/ui/Button'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import Typography from '@/components/shared/ui/Typography'
import { APP_NAME, SUPPORT_EMAIL } from '@/config/appConstants'
import { featureOSLogoURL } from '@/config/staticURLs'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { signinPath, signupPath } from '@/lib/helpers/pathHelpers'
import type { IOrganizationData } from '@/types/organization'
import type { IUserProfile } from '@/types/user'

export default function AuthSideHeader() {
  const { userProfile, organization } = useContext(HNContext) as {
    userProfile: IUserProfile
    organization: IOrganizationData
  }
  const t = useTranslations('auth.labels')
  const router = useRouter()

  const currentOrg = organization || {
    name: APP_NAME,
    avatar: {
      thumb_url: featureOSLogoURL,
    },
  }
  return (
    <div className='border-b border-gray4 p-2 '>
      <div className='mx-auto flex max-w-5xl items-center justify-between'>
        <UnstyledLink href='/' className='flex items-center space-x-2'>
          <Avatar
            url={currentOrg?.avatar?.thumb_url || undefined}
            name={currentOrg?.name}
            rounded
          />
          <Typography.Text className='font-semibold'>
            {currentOrg.name}
          </Typography.Text>
        </UnstyledLink>
        {/* <UnstyledLink
          className='group flex min-w-0 items-center justify-between truncate hover:no-underline focus:outline-none'
          href='/'
        >
          <svg
            width='150'
            height='12'
            viewBox='0 0 605 50'
            fill='currentColor'
            className='text-carbon '
          >
            <path d='M37.3358 49.1826V26.8392H4.52972V49.1826H0V0.817441H4.52972V22.6839H37.3358V0.817441H41.8655V49.1826H37.3358Z'></path>
            <path d='M74.7982 49.1826V0.817441H111.928V4.97275H79.3279V21.3896H99.2312V25.545H79.3279V44.9591H114.673V49.1826H74.7982Z'></path>
            <path d='M179.823 44.9591V49.1826H142.693V0.817441H147.223V44.9591H179.823Z'></path>
            <path d='M244.702 44.9591V49.1826H207.572V0.817441H212.101V44.9591H244.702Z'></path>
            <path d='M292.404 50C285.312 50 279.386 47.5931 274.628 42.7793C269.915 37.9201 267.559 31.9936 267.559 25C267.559 17.8701 269.938 11.921 274.696 7.15259C279.455 2.3842 285.357 0 292.404 0C299.541 0 305.467 2.4069 310.179 7.22071C314.892 12.0345 317.248 17.9609 317.248 25C317.248 32.0391 314.846 37.9655 310.042 42.7793C305.284 47.5931 299.404 50 292.404 50ZM277.922 39.8501C281.72 43.8011 286.547 45.7766 292.404 45.7766C298.26 45.7766 303.087 43.7784 306.885 39.782C310.683 35.7402 312.581 30.8129 312.581 25C312.581 19.0963 310.66 14.1689 306.816 10.218C303.019 6.22162 298.214 4.22344 292.404 4.22344C286.501 4.22344 281.651 6.24432 277.854 10.2861C274.102 14.2825 272.226 19.1871 272.226 25C272.226 30.9037 274.125 35.8538 277.922 39.8501Z'></path>
            <path d='M389.935 0.817441V49.5232H386.846L351.775 6.13079V49.1826H347.383V0.817441H352.942L385.611 41.4169V0.817441H389.935Z'></path>
            <path d='M422.851 49.1826V0.817441H459.981V4.97275H427.381V21.3896H447.284V25.545H427.381V44.9591H462.726V49.1826H422.851Z'></path>
            <path d='M529.592 49.1826L510.237 27.7929L491.089 49.1826H485.53L507.492 24.6594L485.942 0.817441H491.913L510.375 21.3896L528.837 0.817441H534.396L513.189 24.5232L535.288 49.1826H529.592Z'></path>
            <path d='M557.85 4.97275V0.817441H605V4.97275H583.724V49.1826H579.194V4.97275H557.85Z'></path>
          </svg>
        </UnstyledLink> */}
        {!userProfile &&
          (router.pathname === signupPath ? (
            <Button as='a' href={signinPath}>
              {t('signIn')}
            </Button>
          ) : (
            <Button as='a' href={signupPath}>
              {t('signUp')}
            </Button>
          ))}
        {!!userProfile && (
          <div className='flex items-center space-x-2'>
            <UnstyledLink
              href={`mailto:${SUPPORT_EMAIL}`}
              className='whitespace-nowrap font-medium text-gray8'
            >
              Need Help?
            </UnstyledLink>
            <SignoutTrigger>
              <Button>{t('signOut')}</Button>
            </SignoutTrigger>
          </div>
        )}
      </div>
    </div>
  )
}
