import { PlusCircle } from '@phosphor-icons/react'
import Link from 'next/link'
import React, { useContext, useEffect, useState } from 'react'

import AuthSideHeader from '@/components/auth/shared/AuthSideHeader'
import PageTitle from '@/components/shared/common/PageTitle'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import Spinner from '@/components/shared/ui/Loader'
import Tooltip from '@/components/shared/ui/Tooltip'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { redirectToHellonextSupport } from '@/lib/helpers/pathHelpers'
import { templateComponentReplace } from '@/lib/helpers/stringHelpers'
import { listOrganizations } from '@/models/Organization'
import type { IOrganizationItem } from '@/types/organization'

import OrganizationItem from '../profile/organizationList/OrganizationItem'

export default function OrganizationPicker() {
  const t = useTranslations('')
  const { userProfile } = useContext(HNContext)
  const [organizations, setOrganizations] = useState<IOrganizationItem[]>([])
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState<String | null>(null)

  const fetchData = () => {
    listOrganizations()
      .then(setOrganizations)
      .catch((err) => {
        setErrorMessage(err.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => fetchData(), [])

  const renderOrganization = (organization: IOrganizationItem) => (
    <OrganizationItem
      organization={organization}
      key={organization.subdomain}
    />
  )

  if (loading) return <Spinner />
  if (errorMessage) return <div>{errorMessage}</div>

  return (
    <>
      <AuthSideHeader />
      <PageTitle title={'Organization Picker'} />
      <div className='mx-auto max-w-2xl p-4'>
        <div className='flex flex-col divide-y divide-gray4 overflow-hidden rounded border border-gray4 bg-snow   '>
          {organizations.map(renderOrganization)}
          <Tooltip
            show={!userProfile?.can_create_organization}
            customtext={templateComponentReplace(
              t('common.gmailOrgCreationRestrictionMessage'),
              {
                support: (
                  <UnstyledLink
                    className='!text-primary'
                    href={redirectToHellonextSupport()}
                    target={'_blank'}
                  >
                    {t('common.supportText')?.toLowerCase()}
                  </UnstyledLink>
                ),
              }
            )}
          >
            <Link
              href={userProfile?.can_create_organization ? '/new' : '/'}
              className={
                'group flex items-center justify-center space-x-4 !rounded-none !p-4 font-medium !text-gray10 hover:bg-gray3 hover:!text-carbon hover:no-underline   '
              }
            >
              <>
                <PlusCircle
                  weight='bold'
                  className='h-5 w-5 shrink-0 text-gray8 '
                />
                <div className='flex items-center space-x-2 '>
                  <span>Create New Organization</span>
                </div>
              </>
            </Link>
          </Tooltip>
        </div>
      </div>
    </>
  )
}
