import { CaretRight } from '@phosphor-icons/react'
import React, { useContext } from 'react'

import Avatar from '@/components/shared/ui/Avatar'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import HNContext from '@/context/HNContext'
import type { IOrganizationItem } from '@/types/organization'

interface IPropTypes {
  organization: IOrganizationItem
}
export default function OrganizationItem({ organization }: IPropTypes) {
  const { session } = useContext(HNContext) as { session: string }

  const getLink = (org: IOrganizationItem) => {
    let redirectPath = org.organization_link
    if (org.custom_domain_verified) {
      redirectPath = `${redirectPath}auth/verify_token?auth_token=${session}`
    }
    return redirectPath
  }

  return (
    <UnstyledLink
      href={getLink(organization)}
      className='group flex items-center justify-between space-x-4 p-4 font-medium text-gray10 hover:bg-gray3 hover:text-carbon hover:no-underline   '
    >
      <>
        <div className='flex items-center space-x-2'>
          <Avatar
            url={organization.avatar.url}
            name={organization.name}
            rounded
          />
          <span>{organization.name}</span>
        </div>
        <CaretRight
          weight='bold'
          className='h-3 w-3 shrink-0 text-gray8 group-hover:text-carbon'
        />
      </>
    </UnstyledLink>
  )
}
