import type { IKeyValueMap } from 'mobx'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import AdminHeader from '@/components/admin/shared/AdminHeader'
import MainContainer from '@/components/admin/shared/MainContainer'
import KanbanRoadmapPage from '@/components/roadmap/KanbanRoadmapPage'
import RoadmapList from '@/components/roadmap/multipleRoadmap/RoadmapList'
import RoadmapOrderingOption from '@/components/roadmap/multipleRoadmap/shared/RoadmapOrderingOption'
import RoadmapPage from '@/components/roadmap/RoadmapPage'
import PageTitle from '@/components/shared/common/PageTitle'
import TimelineViewToggle from '@/components/shared/components/timeline/TimelineViewToggle'
import { ROADMAP_LIST_CONFIG_DB_KEY } from '@/config/module/roadmapConstants'
import HNContext from '@/context/HNContext'
import HNRoadmapListContext from '@/context/HNRoadmapListContext'
import {
  getLocalStorageData,
  setLocalStorageData,
} from '@/lib/helpers/localStorageHelper'
import type { IRoadmapSingleViewOptions } from '@/types/roadmap'

export default function RoadmapMain() {
  const router = useRouter()
  const { boardSlug } = router.query
  const { organizationCustomization, organizationSetting, isAdmin, buckets } =
    useContext(HNContext)

  const [roadmapConfig, setRoadmapConfig] = useState<
    Partial<IRoadmapSingleViewOptions>
  >({})

  const pageTitle = useMemo(() => {
    return boardSlug
      ? `${buckets?.find((b) => b.name === boardSlug)?.display_name} ${
          organizationSetting?.roadmap_name
        }`
      : organizationSetting?.roadmap_name
  }, [boardSlug, buckets, organizationSetting])

  const roadmapType = useMemo(() => {
    if (isAdmin) return organizationCustomization?.roadmap_type
    return organizationCustomization?.published_roadmap_type
  }, [organizationCustomization?.roadmap_type])

  const handleUpdateContext = (_config: IKeyValueMap) => {
    setRoadmapConfig((oldConfig) => ({
      ...oldConfig,
      ..._config,
    }))
  }

  useEffect(() => {
    if (Object.keys(roadmapConfig).length > 0) {
      setLocalStorageData(ROADMAP_LIST_CONFIG_DB_KEY, roadmapConfig)
    }
  }, [roadmapConfig])

  useEffect(() => {
    setRoadmapConfig({
      sort: 'newest',
      viewMode: 'month',
      ...((getLocalStorageData(ROADMAP_LIST_CONFIG_DB_KEY) as IKeyValueMap) ||
        {}),
    })
  }, [])

  const renderRoadmapComponent = () => {
    if (roadmapType === 'kanban') {
      return <KanbanRoadmapPage readonly />
    }
    if (roadmapType === 'custom') {
      return (
        <HNRoadmapListContext.Provider
          value={{ ...roadmapConfig, updateContext: handleUpdateContext }}
        >
          <MainContainer>
            <AdminHeader
              isHeader
              leftComponent={organizationSetting?.roadmap_plural_name}
              rightComponent={
                <div className='flex items-center space-x-2'>
                  <TimelineViewToggle
                    defaultView={roadmapConfig.viewMode}
                    onChange={(viewMode) => {
                      handleUpdateContext({ viewMode })
                    }}
                  />
                  <RoadmapOrderingOption
                    value={roadmapConfig.sort}
                    onChange={(sort) => handleUpdateContext({ sort })}
                  />
                </div>
              }
            />
            <RoadmapList readonly />
          </MainContainer>
        </HNRoadmapListContext.Provider>
      )
    }
    return <RoadmapPage />
  }
  return (
    <div className='mx-auto'>
      <PageTitle title={pageTitle} />
      {renderRoadmapComponent()}
    </div>
  )
}
