import { useContext } from 'react'

import RoadmapMain from '@/components/roadmap/RoadmapMain'
import OrganizationPicker from '@/components/users/screens/OrganizationPicker'
import UserChangelogList from '@/components/users/screens/UserChangelogList'
import UserPostListPage from '@/components/users/screens/UserPostListPage'
import HNContext from '@/context/HNContext'
import { getHostFromRequest, getSubdomain } from '@/lib/helpers/appHelpers'
import { withSessionInServer } from '@/lib/helpers/nextHelpers'
import type { IChangelog } from '@/types/changelog'
import type { IKnowledgeBaseCollection } from '@/types/knowledgeBase'
import type { IPost } from '@/types/post'

interface IPropTypes {
  posts: IPost[]
  changelogItems: IChangelog[]
  knowledgeBaseCategories: IKnowledgeBaseCollection[]
  isPortal: boolean
}
const Index = (props: IPropTypes) => {
  const { default_module: defaultModule } = useContext(HNContext)

  if (props.isPortal) return <OrganizationPicker />
  switch (defaultModule) {
    case 'changelog':
      return <UserChangelogList initialChangelogs={props.changelogItems} />
    case 'roadmap':
      return <RoadmapMain />
    case 'feedback':
    default:
      return <UserPostListPage initialPosts={props.posts} />
  }
}

export const getServerSideProps = withSessionInServer(async (ctx, _headers) => {
  let posts: IPost[] = []
  let changelogItems: IChangelog[] = []
  const knowledgeBaseCategories: IKnowledgeBaseCollection[] = []

  const { req } = ctx

  if (!req)
    return {
      props: {
        posts,
        changelogItems,
        knowledgeBaseCategories,
        defaultModule: 'feedback',
        isPortal: false,
      },
    }

  const { subdomain } = getSubdomain(getHostFromRequest(req))
  if (subdomain === 'portal') return { props: { isPortal: true } }
  const defaultModule = req?.headers?.defaultModule || 'feedback'
  try {
    switch (req?.headers.defaultModule) {
      case 'feedback':
        posts = []
        break
      case 'changelog':
        // changelogItems = await getSSRChangelogs({ page: 1 }, headers)
        changelogItems = []
        break
      case 'roadmap':
      default:
        break
    }
    return {
      props: {
        posts,
        changelogItems,
        knowledgeBaseCategories,
        defaultModule,
        isPortal: false,
      },
    }
  } catch {
    return {
      props: {
        posts,
        changelogItems,
        knowledgeBaseCategories,
        defaultModule,
        isPortal: false,
      },
    }
  }
})

export default Index
